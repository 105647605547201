import { Anchor, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CrewCard } from "../CrewCard";

export function Culture() {
  return (
    <>

      <section id="culture" className="about section-bg">
        <Container data-aos="fade-up">
          <div className="row no-gutters">

            <div className="content col-xl-5 d-flex align-items-stretch">
              <div className="content">
                <h3>Our Culture</h3>

                <p>
                  Culture doesn't just help attract amazing people, it helps them do their best work. Our company's culture is the
                  engine that dares us to operate differently and challenge the status-quo. We are a place for passionate and daring
                  individuals ready to create lasting impact in everything they do. Our people are curious innovators that seek to
                  understand our client's mission, challenges and strategic goals. They embrace experimentation as a vital tool to
                  accelerate learning needed to jump the technology curve.
                </p>
                <p>
                  Our core values drive behaviors to elevate clients and partners through positive disruption that challenge
                  prevailing solutions and methodologies.
                </p>


                <Link to="/contact">
                  <Anchor href="/contact" className="about-btn">About Us <i className="bx bx-chevron-right"></i></Anchor>
                </Link>
              </div>
            </div>

            <div className="col-xl-7 d-flex align-items-stretch">
              <div className="icon-boxes d-flex flex-column justify-content-center">
                <div className="row">

                  <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                    <i className="bx bx-receipt"></i>
                    <h4>Empower Our Community</h4>
                    <p>
                      People are the secret sauce. We built a trust-based culture that fosters autonomy and transparency unleashing
                      the creative spirit.  We purposefully seek insights and diverse perspectives across our community to create
                      game-changing solutions. Our people are passionate about developing fellow teammates, client's and local
                      communities while leaving egos at the door.
                    </p>
                  </div>
                  <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                    <i className="bx bx-cube-alt"></i>
                    <h4>Create What Matters</h4>
                    <p>
                      Engaged, innovative and productive people need to feel inspired, find meaning, and fully realize the impact of
                      our work. We strive to create a rewarding environment where employees' and colleagues' contributions are valued.
                      As a result, we raise the bar in everything we do to create better tomorrows for our clients, partners and community.
                    </p>
                  </div>
                  <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                    <i className="bx bx-images"></i>
                    <h4>Thrive In Discovery</h4>
                    <p>
                      We believe personal and professional growth takes relentless curiosity, courage and a bias for action. We seek
                      experimentation over slideware to explore new limits transforming the possible. We attract growth-minded people
                      that seek new practices, processes and emerging technologies to foster a learning thriving culture.
                    </p>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </Container>

      </section>


      <section>
        <Container>
            <p>
              Since 2016, our company was founded to deliver modern,adaptive and innovative experiences for Federal agencies
              seeking to improve the status-quo and modernize mission capabilities. We define our success by delivering
              modern, extensible, and secure solutions into production to solve your biggest challenges. Our talented team
              of problem solvers balance people, process and emerging technologies to drive pragmatic solutions that create
              lasting impact. We pair with your organization's expertise and merge our combined talents to build a shared
              vision, embrace change and turn your people and operations into an adaptive competitive advantage.
            </p>
        </Container>
      </section>

      <section id="team" className="team team-section-bg">

        <Container data-aos="fade-up">

          <div className="section-title">
            <h2>The Crew</h2>
            <br/>
            <p>
              The crew is passionate about providing the highest standards in IT delivery experience. We're agile,
              consumer-oriented, results-driven, and inspirng to the workforce.
            </p>
            <br/>
            <p>
              Outside of the office, the crew continues their passion for their hobbies including wood working, home automation, flying, photography,
              brewing, working out, and many others. Many of us have families with kids ranging from babies to college. We value and encourage healthy
              work-life balance.
            </p>
            {/* <p>
              Starting in 2014, Equilibrium Technologies founded a trusted-client relationship driven by a purposeful commitment to better lives
              thru exceptional software solutions and services. Our sustainable and extensible products support highly sensitive mission centers,
              insider-threat operations and create measurable ROI for enterprise business capabilities. We develop people and products, together
              with our partners, using proven Agile methodologies, human-centric design and continuously evaluating emerging open-source technologies.
              Equilibrium Technologies sources a highly talented team to approach every customer with a passionate curiosity and pride to exceed customer expectations.
            </p> */}
          </div>

          <div className="row" id="crewCards">

            <CrewCard name="Wayne Gaskill" craft="Chief Executive Officer" img="./team/team-1.jpg" linkedin="https://linkedin.com/in/waynegaskill" />
            <CrewCard name="Paul Taylor" craft="Full Stack Ninja" img="./team/team-3.jpg" linkedin="https://www.linkedin.com/in/paul-taylor-427aa53a/" />
            <CrewCard name="Adam G" craft="Software Developer" />
            <CrewCard name="Anthony D" craft="Systems Engineer" />
            <CrewCard name="Craig M" craft="Software Developer" />
            <CrewCard name="Eric T" craft="Software Engineer" />
            <CrewCard name="Julie D" craft="Systems Engineer" />
            <CrewCard name="Kurt E" craft="Systems Engineer + DevSecOps Guru" />
            <CrewCard name="Matt S" craft="Systems Engineer + DevSecOps Guru" />
            <CrewCard name="Tara V" craft="Systems Engineer" />

            <CrewCard name="Your Name Here!" craft="Join the Crew!" />

          </div>
        </Container>
      </section>
    </>
  )
}