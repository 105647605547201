import { Container } from "react-bootstrap";
import contentImage from '../assets/img/cyber-criminal.svg';

export function CyberSecurity() {
  return (
    <>
      <section>
        <Container>
          <div className="row">
            <div className="col-lg-7 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up" data-aos-delay="100">
              <h3>Cyber Security</h3>
              <p>
                Our security engineers bring proven experience supporting organizations to align and prioritize its
                secure software development activities with its business/mission requirements, risk tolerances, and
                resources. We build, integrate and deliver capabilities that align to NIST guidance and ensure
                operational readiness and resiliency. We team with our clients to design systems that bake-in
                security at-all levels protect IT digital assets against evolving cyber threats.
              </p>
              <ul>
                <li>NIST 800-503</li>
                <li>NIST SP 800-218</li>
              </ul>
            </div>
          <div className="col-lg-5 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200">
            <img src={contentImage} alt="" className="img-fluid" />
          </div>
          </div>

        </Container>
      </section>
    </>
  )
}