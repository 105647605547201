import { Anchor } from "react-bootstrap";
import { Link } from "react-router-dom";
import defaultImg from './assets/img/team/eqt-q-person.png';

export type CrewCardParams = {
  name: string;
  img?: string;
  craft: string;
  twitter?: string;
  facebook?: string;
  instagram?: string;
  linkedin?: string;
}

export function CrewCard(card: CrewCardParams) {
  return (
    <>
      <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
        <div className="member" data-aos="fade-up" data-aos-delay="400">
          <div className="member-img">
            <img src={card?.img ? card?.img : defaultImg} className={`img-fluid ${card?.img ? '' : 'eqt-person'}`} alt="" />
            <div className="social">
              {card?.twitter &&
                <Anchor href={card?.twitter}><i className="bi bi-twitter"></i></Anchor>
              }
              {card?.facebook &&
                <Anchor href={card?.facebook}><i className="bi bi-facebook"></i></Anchor>
              }
              {card?.instagram &&
                <Anchor href={card?.instagram}><i className="bi bi-instagram"></i></Anchor>
              }
              {card?.linkedin &&
                <Anchor href={card?.linkedin}><i className="bi bi-linkedin"></i></Anchor>
              }
            </div>
          </div>
          <div className="member-info">
            <h4>{card.name}</h4>
            <span>{card.craft}</span>
          </div>
        </div>
      </div>
    </>
  )
}
