import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export function Home() {
  return (
    <>
      {/* APPROVED 20230328 */}
      <section id="hero">
        {/* <video className="heroVideo" muted={true} autoPlay={true} loop={true} playsInline={true}>
          <source data-src="https://dm0qx8t0i9gc9.cloudfront.net/watermarks/video/cW5lDBG/videoblocks-abstract-programming-code-background-software-development-concept_bdh5fisnx__5070e0ff24a25f5b10787c801e4570f8__P360.mp4" type="video/webm" src="https://dm0qx8t0i9gc9.cloudfront.net/watermarks/video/cW5lDBG/videoblocks-abstract-programming-code-background-software-development-concept_bdh5fisnx__5070e0ff24a25f5b10787c801e4570f8__P360.mp4" />
        </video> */}
        <Container data-aos="zoom-out" data-aos-delay="100">
          <div className="row">
            <div className="slogan">
              <h1>| Empower</h1>
              <h1>| Create</h1>
              <h1>| Thrive</h1>
            </div>
            <div className="col-xl-6 slogan">
              <h2>
                We design, develop and deliver transformative digital solutions that enable government
                agencies to improve human experiences.
              </h2>
            </div>
            <Link to="/contact">
              <Button className="btn-get-started scrollto">Contact</Button>
            </Link>
          </div>
        </Container>
      </section>

      <section>
        <Container>
          <div className="row">
            <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
              <h1>Platform Engineering</h1>
              <p>
                Years before the term was coined, we've been a culture of ruthless automation engineers,
                weaving together capabilities into a tapestry of highly efficient cloud-native workflows!
                On our evolving DevSecOps journey, we integrate platforms which are centric to the
                developer experience, enabling faster, more secure, and higher quality business outcomes.
              </p>
            </div>
            <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
              <h1>Software Engineering</h1>
              <p>
                Your tough problems demand the highest quality engineers who will see the solution throughout
                the software development lifecycle. Our experts will tease out business workflows using
                Value Stream Mapping (VSM) exercises (that's 1337 speak for a whiteboard and markers) to
                optimize your business processes and provide the technical knowhow to build, operate, and
                support your products.
              </p>
            </div>
            <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
              <h1>Cloud Adoption & Migration</h1>
              <h6>
                There is no cloud - Just someone else's computer!
              </h6>
              <p>
                Since 2014, our crew have migrated hundreds of payloads from on-prem servers to the cloud.
                We'll help you optimize for your situation, whether that be hardware is EOL and need to
                lift-n-shift, or full renovations of your systems to operate as cloud-native awesomeness!
              </p>
            </div>
            <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
              <h1>Cyber Security</h1>
              <p>
                We have highly skilled cyber security experts skilled in compliance with NIST 800-53,
                NIST SP 800-218, SLSA, and many more highly regulated environments. Our DevSecOps background
                will not only help reduce your vulnerability footprint, but also provide the cccccccccccccccccccccccccccccccccccccccccc
              </p>
            </div>
          </div>
        </Container>
      </section>

    </>
  );
}