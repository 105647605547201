import { Container } from "react-bootstrap";
import contentImage from '../assets/img/cloud-migration.svg';

export function PlatformEngineering() {
  return (
    <>
      <section>
        <Container>
          <div className="row">
            <div className="col-lg-7 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up" data-aos-delay="100">

              <h3>Platform Engineering</h3>
              <p>
                Our Platform Engineering services are designed to elevate your existing software
                teams to deliver software faster, more securely and consistently within today's
                fast-paced digital landscape. We understand that every business is unique, which
                is why we take a client-centric approach to every project. We team closely with
                our clients to  specific compliance requirements and goals, and then leverage our
                unmatched expertise to create a platform solution that is optimized for their mission
                success.
              </p>
              <ul>
                <li>Continuous Integration / Continuous Delivery (CICD) Pipelines</li>
                <li>Secure Software Delivery</li>
                <li>Integration Engineering</li>
                <li>Container Orchestration & K8s</li>
                <li>Change Management & Release Orchestration</li>
                <li>Platform Operations and Maintenance Support</li>
              </ul>

            </div>
            <div className="col-lg-5 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200">
              <img src={contentImage} alt="" className="img-fluid" />
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}