import { Container } from "react-bootstrap";
import contentImage from '../assets/img/web-design-layout.svg';

export function SoftwareEngineering() {
  return (
    <>
      <section>
        <Container>
          <div className="row">

            <div className="col-lg-5 order-1 order-lg-1 text-center" data-aos="fade-up" data-aos-delay="200">
              <img src={contentImage} alt="" className="img-fluid" />
            </div>
            
            <div className="col-lg-7 order-2 order-lg-2 mt-3 mt-lg-0" data-aos="fade-up" data-aos-delay="100">
              <h3>Software Engineering</h3>
              <p>
                We bring deep DevSecOps expertise and agile practices proven to deliver modern and
                secure capabilities to meet mission objectives. Our developers bring an infectious
                excitement to learn, apply new technologies and adopt cloud-native architectures
                that create adaptive, secure and scalable solutions. Our developers are leaders
                that strive to positively influence traditional software development practices to
                reduce risk and drive efficiency across the enterprise.
              </p>
              <ul>
                <li>Agile Software Development</li>
                <li>DevSecOps Consultation & Transformation</li>
                <li>Application Containerization</li>
                <li>Legacy Modernization</li>
                <li>IT Accessibilitiy</li>
              </ul>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}